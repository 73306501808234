import { API_HOST } from "../client/config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LoginPayload, LoginResponse } from "../dto/user";

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}`,
  }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginPayload>({
      query: (payload) => ({
        url: "/token-auth/",
        body: payload,
        method: "POST",
      }),
    }),

    resetPassword: builder.mutation({
      query: (email) => ({
        url: "/public/v100/request-reset-password",
        body: { email },
        method: "POST",
      }),
    }),

    changePassword: builder.mutation({
      query: (payload) => ({
        url: `/public/v100/change-password/${payload.resetCode}`,
        body: {
          password_1: payload.password1,
          password_2: payload.password2,
        },
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
} = loginApi;
