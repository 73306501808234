import { useGetProfileQuery } from "@/lib/services/user";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { getUserToken } from "./services";
import Error from "@/components/Error";

export const Auth = ({ children, isRendalo }: any) => {
  let hasUser = false;
  const { data: profile, error } = useGetProfileQuery();

  if (typeof window !== "undefined") hasUser = getUserToken() != null;

  const router = useRouter();

  useEffect(() => {
    if (!hasUser) {
      router.push("/login");
      return;
    }

    if (isRendalo && profile && !profile.supplier.is_rendalomaq) {
      router.push("/");
      return;
    }
  }, [hasUser, router, profile]);

  if (error) {
    return <Error />;
  }

  if (!hasUser || !profile) {
    return "";
  }

  return children;
};
