import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_HOST } from "../client/config";
import { Customer, NewCustomer } from "../dto/orders";
import { GoPage, ListPageResults } from "../dto/requests";
import { getUserToken } from "../features/auth/services";
import { PAGE_SIZE } from "./config";

export const customersApi = createApi({
  reducerPath: "customersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/suppliers/v100/`,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Token ${getUserToken()}`);
      headers.set("content-type", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Customer"],
  endpoints: (builder) => ({
    getCustomers: builder.query<ListPageResults<Customer>, GoPage | null>({
      providesTags: ["Customer"],
      query: (goPage) => {
        const params = new URLSearchParams();
        if (!goPage?.page_size) {
          params.append("page_size", PAGE_SIZE.toString());
        } else {
          params.append("page_size", `${goPage?.page_size}`);
        }
        if (goPage) {
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.kind) params.append(goPage.kind, "true");
          if (goPage.search) params.append("search", goPage.search);
        }
        return `customers?${params.toString()}`;
      },
    }),

    /*
      This service is used to create short orders. Create a user with just the name.
    */

    createCustomer: builder.mutation({
      invalidatesTags: ["Customer"],
      query: (customer) => {
        return {
          method: "POST",
          url: `${API_HOST}/suppliers/v101/customers/`,
          body: customer,
        };
      },
    }),

    createCustomerWithCompleteData: builder.mutation({
      invalidatesTags: ["Customer"],
      query: (customer) => ({
        url: `${API_HOST}/suppliers/v102/customers/`,
        method: "POST",
        body: customer,
      }),
    }),

    updateCustomer: builder.mutation<
      NewCustomer,
      Partial<NewCustomer> & Pick<Customer, "id">
    >({
      invalidatesTags: ["Customer"],
      query: ({ id, ...data }) => ({
        url: `${API_HOST}/suppliers/v101/customers/${id}/`,
        method: "PUT",
        body: data,
      }),
    }),

    updateCustomerWithCompleteData: builder.mutation<
      NewCustomer,
      Partial<NewCustomer> & Pick<Customer, "id">
    >({
      invalidatesTags: ["Customer"],
      query: ({ id, ...data }) => ({
        url: `${API_HOST}/suppliers/v102/customers/${id}/`,
        method: "PUT",
        body: data,
      }),
    }),

    deleteCustomer: builder.mutation<void, number>({
      invalidatesTags: ["Customer"],
      query: (id) => ({
        url: `customers/${id}`,
        method: "DELETE",
      }),
    }),
    getCustomersByLeadId: builder.query<Customer[], number | string>({
      query: (leadId) =>
        `${API_HOST}/suppliers/v102/leads/${leadId}/organizations/customers`,
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useCreateCustomerMutation,
  useCreateCustomerWithCompleteDataMutation,
  useUpdateCustomerWithCompleteDataMutation,
  useDeleteCustomerMutation,
  useUpdateCustomerMutation,
  useGetCustomersByLeadIdQuery,
} = customersApi;
